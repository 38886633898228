import React from 'react'
import { faCheck, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { faNote } from '@fortawesome/pro-solid-svg-icons'
import { getAccessToken } from '../../../../services/auth.service'
import type {
  PermissionsObject,
} from '../../../../util/checkPermission'
import {
  getPermissions,
} from '../../../../util/checkPermission'
import { useUser } from '../../../../providers/UserProvider'
import { prettyProduct, prettySpeed } from '../../../../util/helpers'
import { Error404 } from '../../../../components/errors/404'
import { Error403 } from '../../../../components/errors/403'
import { CreateNoteModal, NoteRelationType } from '../../../../components/CreateNoteModal'
import { AansluitingDocuments } from './AansluitingDocuments'
import { AansluitingEditModal } from './AansluitingEditModal'
import { AansluitingModemCard } from './AansluitingModemCard'
import { AansluitingDeleteModal } from './AansluitingDeleteModal'
import { AansluitingNotes } from './AansluitingNotes'

export function Aansluiting() {
  const { id } = useParams()
  const queryClient = useQueryClient()

  const aansluitingsgegevensRef = React.useRef<HTMLDivElement>(null)

  const { permissions } = useUser()

  const connectionPermissions: PermissionsObject = getPermissions(
    '/frontend/modules/netwerkbeheer/aansluitingen',
    permissions,
  )
  const workflowPermissions: PermissionsObject = getPermissions(
    '/frontend/modules/netwerkbeheer/aansluitingen/workflow',
    permissions,
  )

  const [editModalOpen, setEditModalOpen] = React.useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [showNoteModal, setShowNoteModal] = React.useState(false)

  const aansluitingQuery = useQuery({
    queryKey: ['aansluiting', id],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/aansluitingen/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })
  // const linksQuery = useQuery(['links', id], async () => {
  //   const response = await fetch(`/api/netwerk/aansluitingen/${id}/links`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + getAccessToken(),
  //     },
  //   });
  //   return await response.json();
  // });

  if (aansluitingQuery.isLoading)
    return <div>Loading...</div>
  if (aansluitingQuery.error) {
    switch ((aansluitingQuery.error as Error).message) {
      case 'Unauthorized':
        return <Error403 />
      case 'Not Found':
        return <Error404 />
      default:
        return (
          <div>
            Fout tijdens laden van aansluiting:
            {' '}
            {(aansluitingQuery.error as Error).message}
          </div>
        )
    }
  }

  const aansluiting = aansluitingQuery.data

  const adres = aansluiting.adres
  const company = aansluiting.company
  const connection = aansluiting.connection
  const workflow = aansluiting.connection.workflow

  const handleWorkflowClick = async (workflowStepId: string) => {
    // Send a POST request to /api/netwerk/aansluitingen/{id}/workflow/complete with a body of { workflowStepId: id }
    const response = await fetch(
      `/api/netwerk/aansluitingen/${id}/workflow/complete`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ workflowStepId }),
      },
    )

    if (response.status === 201) {
      // Invalidate the query to force a re-fetch
      queryClient.invalidateQueries({
        queryKey: ['aansluitingen'],
      })
      queryClient.invalidateQueries({
        queryKey: ['aansluiting', id],
      })
    }
  }

  if (!id) {
    return <div>Geen aansluiting gevonden</div>
  }

  return (
    <div>
      {/* Card for workflow status */}
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Workflow status</h3>
        </div>
        <div className="card-body">
          <div className="row align-items-stretch">
            {workflow.status.map((workflow: any, index: any) => (
              <div className="col-xl" key={index}>
                {workflow.status === 'niet_gestart'
                  ? (
                      <div className="card card-outline card-primary">
                        <div className="card-header">
                          <h3 className="card-title">{workflow.name}</h3>
                          {/* Check to finish */}
                          {workflowPermissions.create && (
                            <button
                              className="btn-xs btn-default card-title float-right"
                              onClick={() => {
                                handleWorkflowClick(workflow.id)
                              }}
                            >
                              {' '}
                              <FontAwesomeIcon icon={faCheck} />
                              {' '}
                            </button>
                          )}
                        </div>
                      </div>
                    )
                  : (
                      <div className="card card-success">
                        <div className="card-header">
                          <h3 className="card-title">{workflow.name}</h3>
                          <h3 className="card-title card-tools">
                            {new Date(workflow.created_at).toLocaleDateString()}
                          </h3>
                        </div>
                      </div>
                    )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Aansluitingsgegevens</h3>
          <div className="card-tools">
            {/* Add button */}
            {connectionPermissions.update && (
              <button
                type="button"
                className="btn btn-primary m-1"
                onClick={() => {
                  setEditModalOpen(true)
                }}
              >
                <FontAwesomeIcon icon={faPen} />
                {' '}
&nbsp; Edit
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary m-1"
              onClick={() => {
                setShowNoteModal(true)
              }}
            >
              <FontAwesomeIcon icon={faNote} />
            </button>

            {connectionPermissions.delete && (
              <Button
                variant="danger"
                className="m-1"
                onClick={() => {
                  setDeleteModalOpen(true)
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4" ref={aansluitingsgegevensRef}>
              <strong>Adres:</strong>
              {' '}
              {`${adres.street}, ${adres.zipcode}, ${adres.city}`}
              {' '}
              <br />
              <strong>Bedrijfsnaam:</strong>
              {' '}
              {company.name}
              {' '}
              <br />
              <strong>CRM:</strong>
              {' '}
              <a
                href={`https://crm.zoho.eu/crm/org20086060573/tab/Accounts/${company.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open in ZoHo
              </a>
              <br />
              <strong>Project:</strong>
              {' '}
              {aansluiting.project.name}
              <br />
              <br />
              <strong>Type Aansluiting:</strong>
              {' '}
              {prettyProduct(connection.type)}
              <br />
              <strong>Aansluiting Snelheid:</strong>
              {' '}
              {prettySpeed(connection.speed)}
              <br />
            </div>
            <div className="col-md-8">
              <AansluitingNotes containerSizeRef={aansluitingsgegevensRef} />
            </div>
          </div>
        </div>
      </div>

      <AansluitingModemCard aansluitingId={id} />

      <AansluitingDocuments aansluitingId={id} />

      {/* Modal */}
      {editModalOpen && (
        <div
          className="modal fade show blur"
          style={{
            display: 'block',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <AansluitingEditModal
            aansluitingId={id}
            defaultProject={{
              id: aansluiting.project.id,
              name: aansluiting.project.name,
            }}
            defaultCompany={{
              id: aansluiting.company.id,
              name: aansluiting.company.name,
            }}
            defaultSpeed={aansluiting.connection.speed}
            setEditModalOpen={setEditModalOpen}
          />
        </div>
      )}

      <AansluitingDeleteModal
        showModal={deleteModalOpen}
        setShowModal={setDeleteModalOpen}
      />

      <CreateNoteModal
        show={showNoteModal}
        handleClose={() => setShowNoteModal(false)}
        relationType={NoteRelationType.AANSLUITING}
        relationId={id}
      />
    </div>
  )
}
