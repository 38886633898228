import React from 'react'
import type { EVC, Endpoint } from '../EVC'
import { DarkModeContext } from '../../../../../providers/DarkModeProvider'

export function VPLSCodeBlock({
  endpoint,
  evc,
  vplsPeers,
  vendor,
}: {
  endpoint: Endpoint
  evc: EVC
  vplsPeers: string[]
  vendor: string
}) {
  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const codeStyles: React.CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
    color: darkMode ? '#a8a8a8' : '#333',
    fontFamily: 'monospace',
    padding: '0.5rem',
    borderRadius: '4px',
    overflowX: 'auto',
  }

  return (
    <>
      <strong>Code:</strong>
      <br />
      <div style={codeStyles}>
        {vendor === 'Brocade' && GenerateBrocadeConfig(endpoint, evc, vplsPeers)}
        {vendor === 'Mikrotik' && GenerateMikrotikConfig(endpoint, evc, vplsPeers)}
      </div>
    </>
  )
}

function GenerateBrocadeConfig(endpoint: Endpoint, evc: EVC, vplsPeers: string[]) {
  const truePeers = vplsPeers.filter(peer => peer !== endpoint.interconnect?.device?.managementAddressAddress)
  return (
    <code>
      configure terminal
      <br />
      router mpls
      <br />
      vpls
      {' '}
      {evc.name}
      {' '}
      {evc.evc_id}
      <br />
      vpls-mtu 1550
      <br />
      {truePeers.map(peer => (
        <React.Fragment key={peer}>
          vpls-peer
          {' '}
          {peer}
          <br />
        </React.Fragment>
      ))}
      {endpoint.vlan.inner
        ? (
            <>
              vlan
              {' '}
              {endpoint.vlan.outer}
              {' '}
              inner
              {' '}
              {endpoint.vlan.inner}
              <br />
            </>
          )
        : (
            <>
              vlan
              {' '}
              {endpoint.vlan.outer}
              <br />
            </>
          )}
      {endpoint.mode == 'TAGGED' || endpoint.mode == 'QINQ'
        ? (
            <>
              tagged e
              {' '}
              {endpoint.interconnect?.port}
              <br />
            </>
          )
        : (
            <>
              untagged e
              {' '}
              {endpoint.interconnect?.port}
              <br />
            </>
          )}
      end
      <br />
      write memory
      <br />
    </code>
  )
}

function GenerateMikrotikConfig(endpoint: Endpoint, evc: EVC, vplsPeers: string[]) {
  const bridgeName = `bridge VPLS${evc.evc_id} ${evc.name}`

  const vplsInterfaces = vplsPeers.map((peer) => {
    if (peer === endpoint.interconnect?.device?.managementAddressAddress) { /* empty */ }
    else {
      return {
        name: `vpls${evc.evc_id} ${evc.name}-${peer}`,
        peer,
      }
    }
  })

  return (
    <code>
      /interface/bridge/add name=&quot;
      {bridgeName}
      &quot; protocol-mode=none mtu=1550 vlan-filtering=yes arp=disabled
      <br />
      {vplsInterfaces.map((vplsInterface) => {
        if (vplsInterface) {
          return (
            <span key={vplsInterface.peer}>
              /interface/vpls/add name=&quot;
              {vplsInterface.name}
              &quot; disabled=no arp=disabled cisco-static-id=
              {evc.evc_id}
              {' '}
              pw-l2mtu=1550 mtu=1550 peer=
              {vplsInterface.peer}
              <br />
              /interface/bridge/port/add bridge=&quot;
              {bridgeName}
              &quot; interface=&quot;
              {vplsInterface.name}
              &quot; horizon=1
              <br />
            </span>
          )
        }
      })}
      {endpoint.vlan.inner
        ? (
            <>
              /interface/vlan/add interface=&quot;
              {endpoint.interconnect?.port}
              &quot; name=&quot;
              {endpoint.interconnect?.port.split(' ')[0]}
              .VLAN
              {endpoint.vlan.outer}
              &quot; vlan-id=
              {endpoint.vlan.outer}
              {' '}
              <br />
              /interface/vlan/add interface=&quot;
              {endpoint.interconnect?.port}
              .VLAN
              {endpoint.vlan.outer}
              &quot; name=&quot;
              {endpoint.interconnect?.port}
              .VLAN
              {endpoint.vlan.outer}
              .VLAN
              {endpoint.vlan.inner}
              {' '}
              VPLS
              {evc.evc_id}
              {' '}
              {evc.name}
              &quot; vlan-id=
              {endpoint.vlan.inner}
              {' '}
              mtu=1550
              <br />
              /interface/bridge/port/add bridge=&quot;
              {bridgeName}
              &quot; interface=&quot;
              {endpoint.interconnect?.port.split(' ')[0]}
              .VLAN
              {endpoint.vlan.outer}
              .VLAN
              {endpoint.vlan.inner}
              {' '}
              VPLS
              {evc.evc_id}
              {' '}
              {evc.name}
              &quot;
              <br />
            </>
          )
        : endpoint.mode === 'TAGGED'
          ? (
              <>
                /interface/vlan/add interface=&quot;
                {endpoint.interconnect?.port}
                &quot; name=&quot;
                {endpoint.interconnect?.port.split(' ')[0]}
                .VLAN
                {endpoint.vlan.outer}
                {' '}
                VPLS
                {evc.evc_id}
                {' '}
                {evc.name}
                &quot; vlan-id=
                {endpoint.vlan.outer}
                {' '}
                mtu=1550
                <br />
                /interface/bridge/port/add bridge=&quot;
                {bridgeName}
                &quot; interface=&quot;
                {endpoint.interconnect?.port.split(' ')[0]}
                .VLAN
                {endpoint.vlan.outer}
                {' '}
                VPLS
                {evc.evc_id}
                {' '}
                {evc.name}
                &quot;
                <br />
              </>
            )
          : (
              `/interface/bridge/port/add bridge="bridge VPLS${evc.evc_id} ${evc.name}" interface="${endpoint.interconnect?.port}"`
            )}
    </code>
  )
}
