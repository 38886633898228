import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { getAccessToken } from '../../../../services/auth.service'
import { ModemBandwidthCard } from '../../Aansluitingen/[id]/ModemBandwidthCard'
import { prettyProduct, prettySpeed } from '../../../../util/helpers'

interface DeviceApiResponse {
  id: string
  deviceName: string
  deviceModel: {
    id: string
    deviceName: string
    deviceType: string
    isCPE: boolean
    isPON: boolean
  }
  managementAddress: {
    address: string
    block: {
      id: string
      network: string
      netmask: number
      version: string
    }
  }
  connection: {
    id: string
    company: {
      id: string
      name: string
    }
    speed: number
    type: string
  }
  evc: {
    id: string
    name: string
    evc_id: number
    speed: number
    company: {
      id: string
      name: string
    }
    serviceProvider: {
      id: string
      name: string
    }
  }[]
}

export function NetworkDevice() {
  const { id } = useParams()
  // const [isLoading, setIsLoading] = React.useState(true);
  const [device, setDevice] = React.useState<DeviceApiResponse | null>(null)

  const navigate = useNavigate()

  const deviceQuery = useQuery({
    queryKey: ['device', id],

    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json() as DeviceApiResponse
    },
  })

  const healthQuery = useQuery({
    queryKey: ['device', id, 'health'],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices/${id}/health`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
    refetchInterval: 1000 * 30,
  })

  React.useEffect(() => {
    if (deviceQuery.data) {
      setDevice(deviceQuery.data)
    }
  }, [deviceQuery.data])

  if (!device)
    return null

  return (
    <>
      <Card className="card-outline card-primary">
        <Card.Header>
          <Card.Title>{device.deviceName}</Card.Title>
          {/* Card tools */}
          <div className="card-tools">
            {device.deviceModel.deviceType !== 'NOCPE' && (
              <>
                {
                  healthQuery.isLoading && (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )
                }
                {/* Render the error message if the query failed */}
                {healthQuery.isError && (
                  <span
                    className="badge badge-warning"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    KAN STATUS NIET OPHALEN
                  </span>
                )}
                {healthQuery.data && healthQuery.data.host === 'UP' && (
                  <span
                    className="badge badge-success mr-1"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    OLT: ONLINE
                  </span>
                )}
                {healthQuery.data && healthQuery.data.host === 'DOWN' && (
                  <span
                    className="badge badge-danger mr-1"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    OLT: OFFLINE
                  </span>
                )}
                { /* Device health status flag */}
                {healthQuery.data && healthQuery.data.host === 'UP' && healthQuery.data.status === 'UP' && (
                  <span
                    className="badge badge-success"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    ONLINE
                  </span>
                )}
                {healthQuery.data && healthQuery.data.host === 'DOWN' && healthQuery.data.status === 'UP' && (
                  <span
                    className="badge badge-success"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    👈 ⚠️ Online
                  </span>
                )}
                {healthQuery.data && healthQuery.data.status === 'DOWN' && (
                  <span
                    className="badge badge-danger"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    OFFLINE
                  </span>
                )}
              </>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <span>
                Model:
                {device.deviceModel.deviceName}
              </span>
              <br />
              <span>
                Type:
                {device.deviceModel.deviceType}
              </span>
              <br />
              <span>
                Is CPE:
                {device.deviceModel.isCPE ? 'Ja' : 'Nee'}
              </span>
              <br />
              <span>
                Is PON:
                {device.deviceModel.isPON ? 'Ja' : 'Nee'}
              </span>
            </Col>
            <Col>
              {device.managementAddress.block.version === 'IPv4' && (
                <>
                  <span>
                    Management IP:
                    {device.managementAddress.address}
                  </span>
                  <br />
                  <span>
                    Management Block:
                    {device.managementAddress.block.network}
                    /
                    {device.managementAddress.block.netmask}
                  </span>
                </>
              )}
            </Col>
            <Col>
              <span>
                Type:
                {prettyProduct(device.connection.type)}
              </span>
              <br />
              <span>
                Speed:
                {prettySpeed(device.connection.speed)}
              </span>
              <br />
              <span>
                Bedrijf:
                {device.connection.company.name}
              </span>
              <br />
              <Button variant="primary" onClick={() => { navigate(`/netwerkbeheer/aansluitingen/${device.connection.id}`) }}>Ga naar aansluiting</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <ModemBandwidthCard modem={device} maximimumBandwidth={device.connection.speed} />
            </Col>
          </Row>
          <Row>
            <Table>
              <thead>
                <tr>
                  <th>EVC ID</th>
                  <th>EVC Naam</th>
                  <th>EVC Snelheid</th>
                  <th>Bedrijf</th>
                  <th>Service Provider</th>
                </tr>
              </thead>
              <tbody>
                {device.evc.map(evc => (
                  <tr key={evc.id} onDoubleClick={() => { navigate(`/netwerkbeheer/evcs/${evc.id}`) }}>
                    <td>{evc.evc_id}</td>
                    <td>{evc.name}</td>
                    <td>{prettySpeed(evc.speed)}</td>
                    <td>{evc.company.name}</td>
                    <td>{evc.serviceProvider.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Card.Body>
        <Card.Footer>

        </Card.Footer>

      </Card>

    </>
  )
}
