import React from 'react'
import type { EVC, Endpoint } from '../EVC'
import { DarkModeContext } from '../../../../../providers/DarkModeProvider'

export function VXLANCodeBlock({
  endpoint,
  evc,
  vplsPeers,
  vendor,
}: {
  endpoint: Endpoint
  evc: EVC
  vplsPeers: string[]
  vendor: string
}) {
  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const codeStyles: React.CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
    color: darkMode ? '#a8a8a8' : '#333',
    fontFamily: 'monospace',
    padding: '0.5rem',
    borderRadius: '4px',
    overflowX: 'auto',
  }

  const vxlanPeers = vplsPeers
    .filter(peer => peer !== endpoint.interconnect?.device?.managementAddressAddress)
    .map((peer) => {
      return peer.replace('10.39.', '10.49.')
    })

  return (
    <>
      <strong>Code:</strong>
      <br />
      <div style={codeStyles}>
        {vendor === 'Brocade' && GenerateBrocadeConfig()}
        {vendor === 'Mikrotik' && GenerateMikrotikConfig(endpoint, evc, vxlanPeers)}
      </div>
    </>
  )
}

function GenerateBrocadeConfig() {
  return (
    <code>
      WARNING! INCOMPATIBLE!
    </code>
  )
}

function GenerateMikrotikConfig(endpoint: Endpoint, evc: EVC, vxlanPeers: string[]) {
  const bridgeName = `bridge VXLAN${evc.evc_id} ${evc.name}`
  const vxlanName = `vxlan${evc.evc_id} ${evc.name}`

  return (
    <code>
      /interface/bridge/add name=&quot;
      {bridgeName}
      &quot; protocol-mode=none mtu=1550 vlan-filtering=yes arp=disabled
      <br />

      /interface/vxlan/add local-address=
      {endpoint.interconnect?.device?.managementAddressAddress.replace('10.39.', '10.49.')}
      {' '}
      name=&quot;
      {vxlanName}
      &quot;
      {' '}
      port=8472
      {' '}
      vni=
      {evc.evc_id}
      {' '}
      vrf=main
      {' '}
      vteps-ip-version=ipv4
      <br />
      {vxlanPeers.map((vxlanPeer) => {
        return (
          <span key={vxlanPeer}>
            /interface/vxlan/vteps/add
            {' '}
            interface=&quot;
            {vxlanName}
            &quot;
            {' '}
            remote-ip=
            {vxlanPeer}
            <br />
          </span>
        )
      })}
      /interface/bridge/port/add bridge=&quot;
      {bridgeName}
      &quot; interface=&quot;
      {vxlanName}
      &quot; horizon=1
      pvid=2
      tag-stacking=yes
      <br />
      {endpoint.vlan.inner
        ? (
            <>
              /interface/vlan/add interface=&quot;
              {endpoint.interconnect?.port}
              &quot; name=&quot;
              {endpoint.interconnect?.port.split(' ')[0]}
              .VLAN
              {endpoint.vlan.outer}
              &quot; vlan-id=
              {endpoint.vlan.outer}
              {' '}
              <br />
              /interface/vlan/add interface=&quot;
              {endpoint.interconnect?.port}
              .VLAN
              {endpoint.vlan.outer}
              &quot; name=&quot;
              {endpoint.interconnect?.port}
              .VLAN
              {endpoint.vlan.outer}
              .VLAN
              {endpoint.vlan.inner}
              {' '}
              VXLAN
              {evc.evc_id}
              {' '}
              {evc.name}
              &quot; vlan-id=
              {endpoint.vlan.inner}
              {' '}
              mtu=1550
              <br />
              /interface/bridge/port/add bridge=&quot;
              {bridgeName}
              &quot; interface=&quot;
              {endpoint.interconnect?.port.split(' ')[0]}
              .VLAN
              {endpoint.vlan.outer}
              .VLAN
              {endpoint.vlan.inner}
              {' '}
              VXLAN
              {evc.evc_id}
              {' '}
              {evc.name}
              &quot;
              pvid=2
              tag-stacking=yes
              <br />
            </>
          )
        : endpoint.mode === 'TAGGED'
          ? (
              <>
                /interface/vlan/add interface=&quot;
                {endpoint.interconnect?.port}
                &quot; name=&quot;
                {endpoint.interconnect?.port.split(' ')[0]}
                .VLAN
                {endpoint.vlan.outer}
                {' '}
                VXLAN
                {evc.evc_id}
                {' '}
                {evc.name}
                &quot; vlan-id=
                {endpoint.vlan.outer}
                {' '}
                mtu=1550
                <br />
                /interface/bridge/port/add bridge=&quot;
                {bridgeName}
                &quot; interface=&quot;
                {endpoint.interconnect?.port.split(' ')[0]}
                .VLAN
                {endpoint.vlan.outer}
                {' '}
                VXLAN
                {evc.evc_id}
                {' '}
                {evc.name}
                &quot;
                pvid=2
                tag-stacking=yes
                <br />
              </>
            )
          : (
              `/interface/bridge/port/add bridge="bridge VXLAN${evc.evc_id} ${evc.name}" interface="${endpoint.interconnect?.port}" pvid=2 tag-stacking=yes`
            )}
    </code>
  )
}
