import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { getAccessToken } from '../../../../services/auth.service'
import { selectTheme } from '../../../../util/helpers'
import { DarkModeContext } from '../../../../providers/DarkModeProvider'
import { EndpointType } from './EVC'
import type { EVC, Endpoint } from './EVC'
import { VPLSCodeBlock } from './ConfigBuilder/VPLS'
import { VXLANCodeBlock } from './ConfigBuilder/VXLAN'
import { EvcType } from '../../EVC'

interface EVCDraftProps {
  evc: EVC
}

export function EVCDraft(props: EVCDraftProps) {
  const { evc } = props

  const [vplsPeers, setVplsPeers] = React.useState<string[]>([])

  const queryClient = useQueryClient()

  React.useEffect(() => {
    const tempPeers: string[] = [...vplsPeers]

    evc.endpoints?.forEach((endpoint) => {
      if (endpoint.type === EndpointType.INTERCONNECT) {
        const managementAddress = endpoint.interconnect?.device?.managementAddressAddress
        if (managementAddress) {
          // Only add peer if it's not already in the list
          if (!tempPeers.includes(managementAddress)) {
            tempPeers.push(managementAddress)
          }
        }
      }
    })

    setVplsPeers(tempPeers)
  }, [])

  const handleSubmit = async () => {
    const response = await fetch(`/api/netwerk/evcs/${evc.id}/activate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
    })

    if (!response.ok) {
      toast.error('Er is iets misgegaan bij het activeren van de EVC')
      throw new Error(response.statusText)
    }

    queryClient.invalidateQueries({
      queryKey: ['evcs', evc.id],
    })

    toast.success('EVC succesvol geactiveerd')
  }

  const sortedEvcEndpoints = evc.endpoints?.sort((a, b) => {
    if (a.type == EndpointType.INTERCONNECT && b.type == EndpointType.DEVICE) {
      return -1
    }
    if (a.type == EndpointType.DEVICE && b.type == EndpointType.INTERCONNECT) {
      return 1
    }
    // Sort by interconnect name
    if ((a.interconnect?.name ?? '') < (b.interconnect?.name ?? '')) {
      return -1
    }
    if ((a.interconnect?.name ?? '') > (b.interconnect?.name ?? '')) {
      return 1
    }
    // Sort by device name
    if ((a.device?.deviceName ?? '') < (b.device?.deviceName ?? '')) {
      return -1
    }
    if ((a.device?.deviceName ?? '') > (b.device?.deviceName ?? '')) {
      return 1
    }

    return 0
  })

  return (
    <>
      {/* General info card */}
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">
            [
            {evc.state}
            ] EVC:
            {evc.name}
          </h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <strong>EVC ID:</strong>
              {' '}
              {evc.evc_id == -1 ? 'Automatisch toekennen' : evc.evc_id}
              <br />
            </div>
            <div className="col-md-6">
              <strong>Peers:</strong>
              <ul>
                {vplsPeers.map(peer => (
                  <li key={peer}>{evc.type === EvcType.VXLAN ? peer.replace('10.39.', ' 10.49.') : peer}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Card per interconnect */}
      {sortedEvcEndpoints && sortedEvcEndpoints.map(endpoint => (
        <EVCCard key={endpoint.id} evc={evc} endpoint={endpoint} vplsPeers={vplsPeers} setVplsPeers={setVplsPeers} />
      ),
      )}

      {/* Submit button */}
      <div className="card card-outline card-primary">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-primary" onClick={handleSubmit}>Activeren</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function EVCCard({
  evc,
  endpoint,
  vplsPeers,
  setVplsPeers,
}: {
  evc: EVC
  endpoint: Endpoint
  vplsPeers: string[]
  setVplsPeers: React.Dispatch<React.SetStateAction<string[]>>
}) {
  switch (endpoint.type) {
    case EndpointType.INTERCONNECT:
      return EndpointInterconnect(evc, endpoint, vplsPeers)
    case EndpointType.DEVICE:
      return EndpointDevice(evc, endpoint, vplsPeers, setVplsPeers)
  }
  return (
    <div>
      WAARSCHUWING: INCORRECT ENDPOINT TYPE
    </div>
  )
}

function EndpointInterconnect(evc: EVC, endpoint: Endpoint, vplsPeers: string[]) {
  const darkMode = React.useContext(DarkModeContext).isDarkMode

  const endpointName = endpoint.interconnect?.name ?? ''

  const codeStyles: React.CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
    color: darkMode ? '#a8a8a8' : '#333',
    fontFamily: 'monospace',
    padding: '0.5rem',
    borderRadius: '4px',
    overflowX: 'auto',
  }

  const isMonitored = !!endpoint.monitoring?.application

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Interconnect:
          {`${endpointName}`}
        </h3>
        <div className="card-tools">
          {isMonitored && (
            <span className="badge badge-success">Gemonitord</span>
          )}
          {!isMonitored && (
            <button type="button" className="btn btn-primary btn-sm" style={{ marginLeft: '10px' }}>
              <i className="fas fa-plus"></i>
              {' '}
              Monitoring toevoegen
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <strong>Device:</strong>
            {' '}
            {endpoint.interconnect?.device.deviceName}
            <br />
            <strong>Vlan type:</strong>
            {' '}
            {endpoint.mode}
            <br />
            <strong>Vlan:</strong>
            {' '}
            {endpoint.vlan.inner ? `${endpoint.vlan.outer}.${endpoint.vlan.inner}` : endpoint.vlan.outer}
            <br />
            <strong>Poort:</strong>
            {' '}
            {endpoint.interconnect?.port}
          </div>
        </div>
        {/* Code helper */}
        <div className="row">
          <div className="col-md-12">
            {evc.type === 'VPLS' && (
              <VPLSCodeBlock endpoint={endpoint} evc={evc} vplsPeers={vplsPeers} vendor={endpoint.interconnect?.device.deviceModel.vendor ?? ''} />
            )}
            {evc.type === 'VXLAN' && (
              <VXLANCodeBlock endpoint={endpoint} evc={evc} vplsPeers={vplsPeers} vendor={endpoint.interconnect?.device.deviceModel.vendor ?? ''} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface selectType {
  value: string
  label: string
}

function EndpointDevice(evc: EVC, endpoint: Endpoint, vplsPeers: string[], setVplsPeers: React.Dispatch<React.SetStateAction<string[]>>) {
  const [selectedVendor, setSelectedVendor] = React.useState('')
  const [selectedIp, setSelectedIp] = React.useState('')
  const [selectedPort, setSelectedPort] = React.useState('')
  const [tagged, setTagged] = React.useState(true)

  // Find the first evc endpoint inner vlan and use as default, use outer vlan if inner is not set, default to 1000 if no vlan is set
  const [vlan, setVlan] = React.useState(evc.endpoints?.find(endpoint => endpoint.vlan.inner)?.vlan.inner ?? evc.endpoints?.find(endpoint => endpoint.vlan.outer)?.vlan.outer ?? 1000)

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  if (!endpoint.device) {
    throw new Error('Endpoint device is undefined')
  }

  const selectOptions = [
    { value: 'Mikrotik', label: 'Mikrotik' },
    { value: 'Brocade', label: 'Brocade' },
  ] as selectType[]

  const handleIPChange = async (e: any) => {
    setVplsPeers((peers) => {
      const tempPeers = [...peers]
      const index = tempPeers.indexOf(selectedIp)
      if (index > -1 && evc.endpoints && !evc.endpoints.some(endpoint => endpoint.interconnect?.device?.managementAddressAddress === selectedIp)) {
        tempPeers.splice(index, 1)
      }
      if (!tempPeers.includes(e.target.value)) {
        tempPeers.push(e.target.value)
      }
      return tempPeers
    })
    setSelectedIp(e.target.value)
  }

  const codeStyles: React.CSSProperties = {
    transition: 'all 0.1s ease-in-out',
    backgroundColor: darkMode ? '#2d2d2d' : '#f7f7f7',
    color: darkMode ? '#a8a8a8' : '#333',
    fontFamily: 'monospace',
    padding: '0.5rem',
    borderRadius: '4px',
    overflowX: 'auto',
  }

  const dummyEndpoint
  = {
    id: endpoint.id,
    mode: tagged ? 'TAGGED' : 'UNTAGGED',
    type: EndpointType.DEVICE,
    vlan: {
      inner: 0,
      outer: vlan,
    },
    device: endpoint.device,
    monitoring: endpoint.monitoring,
    interconnect: {
      id: '',
      name: '',
      description: '',
      device: {
        id: '',
        managementAddressAddress: selectedIp,
        deviceName: '',
        deviceModel: {
          id: '',
          vendor: '',
        },
      },
      port: selectedPort,
    },
  }

  return (
    <div className="card card-outline card-primary" key={endpoint.id}>
      <div className="card-header">
        <h3 className="card-title">
          Modem:
          {`${endpoint.device.deviceName}`}
        </h3>
      </div>
      <div className="card-body">
        {/* Config helper, 1 select box with options Mikrotik and Brocade, 1 field for VPLS endpoint ip */}
        <div className="row">
          <div className="col-md">
            <strong>Vendor:</strong>
            <Select
              theme={selectStyle}
              options={selectOptions}
              onChange={e => setSelectedVendor(e?.value ?? '')}
              value={selectOptions.find(option => option.value == selectedVendor)}
            />
            <br />
          </div>
          <div className="col-md">
            <strong>IP:</strong>
            <input
              type="text"
              className="form-control"
              placeholder="10.39.0.1"
              value={selectedIp}
              onChange={handleIPChange}
            />
          </div>
          <div className="col-md">
            <strong>Poort:</strong>
            <input
              type="text"
              className="form-control"
              placeholder="1/1"
              value={selectedPort}
              onChange={e => setSelectedPort(e.target.value)}
            />
          </div>
          <div className="col-md">
            <strong>Vlan:</strong>
            <input
              type="number"
              className="form-control"
              placeholder="1000"
              value={vlan}
              onChange={e => setVlan(Number.parseInt(e.target.value))}
            />
          </div>
          <div className="col-md">
            <strong>Tagged:</strong>
            <br />
            <input
              type="checkbox"
              checked={tagged}
              onChange={e => setTagged(e.target.checked)}
            />
          </div>
        </div>
        {/* Code helper */}
        <div className="row">
          <div className="col-md-12">
            {evc.type === 'VPLS' && (
              <VPLSCodeBlock endpoint={dummyEndpoint} evc={evc} vplsPeers={vplsPeers} vendor={selectedVendor} />
            )}
            {evc.type === 'VXLAN' && (
              <VXLANCodeBlock endpoint={dummyEndpoint} evc={evc} vplsPeers={vplsPeers} vendor={selectedVendor} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
