import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { getAccessToken } from '../../services/auth.service'

enum EVC_Port_Mode {
  UNTAGGED = 'UNTAGGED',
  TAGGED = 'TAGGED',
  QINQ = 'QINQ',
}

enum EvcState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  DECOMMISSIONED = 'DECOMMISSIONED',
}

export enum EvcType {
  VPLS = 'VPLS',
  VXLAN = 'VXLAN',
}

interface EVC_Endpoint {
  id: string
  port_mode: EVC_Port_Mode
  inner_vlan: number
  outer_vlan: number
}

interface EVC {
  id: string
  name: string
  type: EvcType
  evc_id: string
  endpoints: EVC_Endpoint[]
  state: EvcState
  created: string
  updated: string
  deleted: string
}

interface ApiResponse {
  total_rows: number
  total_pages: number
  page: number
  result: EVC[]
}

export function EVCOverview() {
  const [limit, setLimit] = React.useState(25)
  const [page, setPage] = React.useState(0)
  const [nameFilter, setNameFilter] = React.useState('')
  const [evcIdFilter, setEvcIdFilter] = React.useState('')
  const [evcTypeFilter, setEvcTypeFilter] = React.useState('')
  const [stateFilter, setStateFilter] = React.useState('')

  const [evcs, setEvcs] = useState<ApiResponse>({
    total_rows: 0,
    total_pages: 0,
    page: 0,
    result: [],
  })

  const navigate = useNavigate()

  const evcQuery = useQuery({
    queryKey: ['evcs', {
      limit,
      page,
      nameFilter,
      evcIdFilter,
      evcTypeFilter,
      stateFilter,
    }],

    queryFn: async () => {
      let queryString = `/api/netwerk/evcs?limit=${limit}&page=${page}`

      if (nameFilter) {
        queryString += `&naam=${encodeURIComponent(nameFilter)}`
      }

      if (evcTypeFilter) {
        queryString += `&type=${encodeURIComponent(evcTypeFilter)}`
      }

      if (evcIdFilter) {
        queryString += `&evc_id=${encodeURIComponent(evcIdFilter)}`
      }

      if (stateFilter) {
        queryString += `&state=${encodeURIComponent(stateFilter)}`
      }

      const response = await fetch(queryString, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    },
  })

  React.useEffect(() => {
    if (evcQuery.data) {
      setEvcs(evcQuery.data)
    }
  }, [evcQuery.data])

  const onLimitChange = (event: { target: { value: string } }) => {
    setLimit(Number.parseInt(event.target.value))

    // Calculate current index
    const currentIndex = page * limit

    // Calculate new offset
    const newOffset = Math.floor(currentIndex / Number.parseInt(event.target.value))

    setPage(newOffset)
  }

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">
          EVC&apos;s (
          { evcs.total_rows }
          )
        </h3>
        <div className="card-tools">
          <button
            type="button"
            className="btn btn-tool btn-primary"
            onClick={() => navigate('/netwerkbeheer/evcs/toevoegen')}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      <div className="card-body table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Naam</th>
              <th scope="col">EVC Type</th>
              <th scope="col">EVC ID</th>
              <th scope="col">Status</th>
              <th scope="col">Aangemaakt</th>
              <th scope="col">Bijgewerkt</th>
            </tr>
          </thead>
          <tbody>
            {/* Collumn search */}
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={nameFilter}
                  onChange={
                    (event) => {
                      setNameFilter(event.target.value)
                      setPage(0)
                    }
                  }
                />
              </td>
              <td>
                <select
                  className="form-control"
                  value={evcTypeFilter}
                  onChange={
                    (event) => {
                      setEvcTypeFilter(event.target.value)
                      setPage(0)
                    }
                  }
                >
                  <option value="">Alle</option>
                  <option value="VPLS">VPLS</option>
                  <option value="VXLAN">VXLAN</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={evcIdFilter}
                  onChange={
                    (event) => {
                      setEvcIdFilter(event.target.value)
                      setPage(0)
                    }
                  }
                />
              </td>
              <td>
                <select
                  className="form-control"
                  value={stateFilter}
                  onChange={
                    (event) => {
                      setStateFilter(event.target.value)
                      setPage(0)
                    }
                  }
                >
                  <option value="">Alle</option>
                  <option value="REQUESTED">Aangevraagd</option>
                  <option value="DRAFT">Concept</option>
                  <option value="ACTIVE">Actief</option>
                  <option value="REJECTED">Afgewezen</option>
                  <option value="DECOMMISSIONED">Stopgezet</option>
                </select>
              </td>
              <td />
              <td />
            </tr>
            {evcs.result.map(evc => (
              <tr key={evc.id} onDoubleClick={() => navigate(`/netwerkbeheer/evcs/${evc.id}`)}>
                <td>{evc.name}</td>
                <td>{evc.type}</td>
                <td>{evc.evc_id}</td>
                <td>{evc.state}</td>
                <td>{evc.created}</td>
                <td>{evc.updated}</td>
              </tr>
            ))}
          </tbody>
          {/* Table pagination / result limiter */}
          <tfoot>
            <tr>
              <td colSpan={7}>
                <div className="float-right">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page - 1)}>&laquo;</button>
                      </li>
                      <li className={`page-item ${page === 0 || page === 1 || !evcs ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page - 2)}>{page - 1}</button>
                      </li>
                      <li className={`page-item ${page === 0 || !evcs ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page - 1)}>{page}</button>
                      </li>
                      <li className="page-item active">
                        <button className="page-link">{page + 1}</button>
                      </li>
                      <li className={`page-item ${page === Math.ceil(evcs?.total_rows / limit) - 1 || !evcs ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page + 1)}>{page + 2}</button>
                      </li>
                      <li className={`page-item ${page === Math.ceil(evcs?.total_rows / limit) - 1 || page === Math.ceil(evcs?.total_rows / limit) - 2 || !evcs ? 'd-none' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page + 2)}>{page + 3}</button>
                      </li>
                      <li className={`page-item ${page === Math.ceil(evcs?.total_rows / limit) - 1 || !evcs ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setPage(page + 1)}>&raquo;</button>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="float-left">
                  <div className="form-group">
                    <label htmlFor="limit">Resultaten per pagina</label>
                    <select className="form-control" id="limit" onChange={onLimitChange} value={limit}>
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

    </div>
  )
}
