import { useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { faLock, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { getAccessToken } from '../../../services/auth.service'
import { selectTheme } from '../../../util/helpers'
import { DarkModeContext } from '../../../providers/DarkModeProvider'

interface ServiceProvider {
  id: string
  name: string
}

interface ServiceProviderOptions {
  value: ServiceProvider
  label: string
}

enum EVCEndpointType {
  INTERCONNECTGROUP = 'INTERCONNECTGROUP',
  DEVICE = 'DEVICE',
  INTERCONNECT = 'INTERCONNECT',
  ADDRESS = 'ADDRESS',
  CONNECTION = 'CONNECTION',
}

enum EvcPortMode {
  UNTAGGED = 'UNTAGGED',
  TAGGED = 'TAGGED',
  QINQ = 'QINQ',
}

enum EvcType {
  VPLS = 'VPLS',
  VXLAN = 'VXLAN',
}

interface EVCEndpoint {
  type: EVCEndpointType
  portMode: EvcPortMode
  vlan: {
    inner?: number
    outer?: number
  }
  interconnectGroup?: {
    id: string
    name: string
  }
  interconnectID?: string
  deviceID?: string
  devicePortID?: string
  address?: {
    id: string
    street: string
    homeNumber: string
    homeLetter: string
    homeAddition: string
    zipcode: string
    city: string
  }
}

interface selectType {
  value: string
  label: string
}

const speedOptions = [
  { value: '2', label: '2 Mbit/s' },
  { value: '25', label: '25 Mbit/s' },
  { value: '50', label: '50 Mbit/s' },
  { value: '100', label: '100 Mbit/s' },
  { value: '200', label: '200 Mbit/s' },
  { value: '250', label: '250 Mbit/s' },
  { value: '500', label: '500 Mbit/s' },
  { value: '1000', label: '1000 Mbit/s' },
]

export function EVCToevoegen() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [evcName, setEvcName] = React.useState<string>('')
  const [evcType, setEvcType] = React.useState<EvcType>(EvcType.VXLAN)
  const [evcID, setEvcID] = React.useState<string>('Automatisch genereren')
  const [serviceProvider, setServiceProvider] = React.useState<ServiceProvider | null>(null)
  const [serviceProviderOptions, setServiceProviderOptions] = React.useState<ServiceProviderOptions[]>([])
  const [isEditingEvcID, setIsEditingEvcID] = React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState(false)
  const [evcSpeed, setEVCSpeed] = React.useState<string>('0')
  const [customers, setCustomers] = React.useState([])

  const [customerSearch, setCustomerSearch] = React.useState<string>('')

  const [selectedCustomer, setSelectedCustomer] = React.useState<selectType | null>(null)

  const toggleShow = () => setShowModal(!showModal)

  const [evcEndpoints, setEvcEndpoints] = React.useState<EVCEndpoint[]>([])

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const serviceProviderQuery = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const response = await fetch(`/api/users/groups?provider=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      const json = await response.json()

      if (json.error) {
        throw new Error(json.error)
      }

      return json
    },
  })

  React.useEffect(() => {
    if (serviceProviderQuery.data) {
      setServiceProviderOptions(serviceProviderQuery.data.map((serviceProvider: ServiceProvider) => {
        return {
          value: serviceProvider,
          label: serviceProvider.name,
        }
      }))
    }
  }, [serviceProviderQuery.data])

  const handleEnableEditing = () => {
    setIsEditingEvcID(true)
    setEvcID('')
  }

  const customerQuery = useQuery({
    queryKey: ['customer', {
      customerSearch,
    }],

    queryFn: async () => {
      let queryString = '/api/crm/companies?limit=5'

      if (customerSearch) {
        queryString += `&naam=${encodeURIComponent(customerSearch)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (customerQuery.data) {
      setCustomers(customerQuery.data.data.map((bedrijf: any) => {
        return {
          label: bedrijf.Account_Name,
          value: bedrijf.id,
        } as selectType
      }))
    }
  }, [customerQuery.data])

  const handleSubmit = async () => {
    const response = await fetch('/api/netwerk/evcs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        naam: evcName,
        type: evcType,
        evc_id: evcID === 'Automatisch genereren' ? -1 : Number(evcID),
        company_id: selectedCustomer,
        serviceprovider_id: serviceProvider?.id,
        speed: evcSpeed ? Number(evcSpeed) : undefined,
        endpoints: evcEndpoints.map((endpoint: EVCEndpoint) => {
          return {
            mode: endpoint.portMode,
            device_id: endpoint.deviceID,
            device_port_id: endpoint.devicePortID,
            interconnect_id: endpoint.interconnectID,
            interconnectGroup_id: endpoint.interconnectGroup?.id,
            address_id: endpoint.address?.id,
            vlan: {
              inner: endpoint.vlan.inner,
              outer: endpoint.vlan.outer,
            },
            type: endpoint.type,
          }
        }),
        state: 'REQUESTED',
      }),
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    const json = await response.json()

    toast.success('EVC aangemaakt')
    queryClient.invalidateQueries({
      queryKey: ['aansluitingen'],
    })
    navigate(`/netwerkbeheer/evcs/${json.id}`)
  }

  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">EVC Toevoegen</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>EVC Naam</label>
              <input type="text" className="form-control" onChange={(e) => { setEvcName(e.target.value) }} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>EVC Type</label>
              <Select
                options={[
                  { value: EvcType.VPLS, label: 'VPLS' },
                  { value: EvcType.VXLAN, label: 'VXLAN' },
                ]}
                value={{ value: evcType, label: evcType }}
                onChange={(event: any) => { setEvcType(event.value) }}
                isLoading={false}
                isDisabled={false}
                placeholder="Selecteer een type"
                theme={selectStyle}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>EVC ID</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  value={evcID}
                  onChange={(e) => { setEvcID(e.target.value) }}
                  disabled={!isEditingEvcID}
                />
                {!isEditingEvcID && (
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleEnableEditing}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Service Provider</label>
              <Select
                options={serviceProviderOptions}
                onChange={(event: any) => { setServiceProvider(event.value) }}
                isLoading={false}
                isDisabled={false}
                placeholder="Zoek een service provider"
                theme={selectStyle}
                noOptionsMessage={
                  () => 'Geen groepen gevonden'
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>Klant</label>
              <Select
                options={customers}
                onChange={(event: any) => { setSelectedCustomer(event.value) }}
                onInputChange={(value) => { setCustomerSearch(value) }}
                // value={customers.find(option => option.value === selectedCustomer)}
                isDisabled={false}
                placeholder="Zoek een klant"
                theme={selectStyle}
                noOptionsMessage={
                  () => 'Geen groepen gevonden'
                }
                isLoading={customerQuery.isLoading}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>EVC Snelheid</label>
              <Creatable
                value={speedOptions.find(option => option.value === evcSpeed)}
                options={speedOptions}
                onChange={(event: any) => { setEVCSpeed(event.value) }}
                isLoading={false}
                isDisabled={false}
                placeholder="Selecteer een snelheid"
                theme={selectStyle}
                onCreateOption={(value) => {
                  speedOptions.push({ label: `Anders: ${value} Mbit/s`, value })
                  setEVCSpeed(value)
                }}
                formatCreateLabel={value => `Anders: ${value} Mbit/s`}
              />
            </div>
          </div>
        </div>

        {/* EVC Endpoints Table */}
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Endpoint ID</th>
                <th>Type</th>
                <th>Port Mode</th>
                <th>VLAN</th>
                <th>Details</th>
                <th>
                  <button
                    className="btn btn-primary btn-tool"
                    onClick={() => setShowModal(true)}
                    disabled={!serviceProvider}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {evcEndpoints.map((evcEndpoint: EVCEndpoint, index) => {
                return (
                  <EVCEndpointRow
                    index={index}
                    evcEndpoint={evcEndpoint}
                    setEvcEndpoints={setEvcEndpoints}
                    key={index}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card-footer">
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Toevoegen</button>
      </div>

      <AddEVCEndpointModal
        show={showModal}
        setShowModal={setShowModal}
        setEvcEndpoints={setEvcEndpoints}
      />
    </div>
  )
}

function EVCEndpointRow(props: {
  index: number
  evcEndpoint: EVCEndpoint
  setEvcEndpoints: React.Dispatch<React.SetStateAction<EVCEndpoint[]>>
}) {
  const { evcEndpoint } = props

  const [interconnect, setInterconnect] = React.useState<any>(null)
  const [device, setDevice] = React.useState<any>(null)

  const interconnectQuery = useQuery({
    queryKey: ['interconnect', evcEndpoint.interconnectID],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/interconnects/${evcEndpoint.interconnectID}`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
    enabled: evcEndpoint.interconnectID != null,
  })

  React.useEffect(() => {
    if (interconnectQuery.data) {
      setInterconnect(interconnectQuery.data)
    }
  }, [interconnectQuery.data])

  const deviceQuery = useQuery({
    queryKey: ['device', evcEndpoint.deviceID],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/devices/${evcEndpoint.deviceID}`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
    enabled: evcEndpoint.deviceID != null,
  })

  React.useEffect(() => {
    if (deviceQuery.data) {
      setDevice(deviceQuery.data)
    }
  }, [deviceQuery.data])

  const handleDelete = () => {
    props.setEvcEndpoints((prevEvcEndpoints) => {
      // Remove the index from the array
      const newEvcEndpoints = [...prevEvcEndpoints]
      newEvcEndpoints.splice(props.index, 1)
      return newEvcEndpoints
    })
  }

  return (
    <tr key={props.index}>
      <td>{props.index}</td>
      <td>{evcEndpoint.type}</td>
      <td>{evcEndpoint.portMode}</td>
      <td>
        {evcEndpoint.portMode == EvcPortMode.QINQ && (
          <span>
            {evcEndpoint.vlan.outer}
            {' '}
            /
            {' '}
            {evcEndpoint.vlan.inner}
          </span>
        )}
        {evcEndpoint.portMode == EvcPortMode.TAGGED && (
          <span>{evcEndpoint.vlan.outer}</span>
        )}
        {evcEndpoint.portMode == EvcPortMode.UNTAGGED && (
          <span></span>
        )}
      </td>
      {evcEndpoint.type == EVCEndpointType.INTERCONNECTGROUP && (
        <td>{evcEndpoint.interconnectGroup?.name}</td>
      )}
      {evcEndpoint.type == EVCEndpointType.INTERCONNECT && (
        <td>{interconnect?.name}</td>
      )}
      {evcEndpoint.type == EVCEndpointType.DEVICE && (
        <td>{device?.deviceName}</td>
      )}
      {evcEndpoint.type == EVCEndpointType.ADDRESS && (
        <td>
          {evcEndpoint.address?.street}
          {' '}
          {evcEndpoint.address?.homeNumber}
          {evcEndpoint.address?.homeLetter}
          {evcEndpoint.address?.homeAddition}
          ,
          {' '}
          {evcEndpoint.address?.zipcode}
          {' '}
          {evcEndpoint.address?.city}
        </td>
      )}
      <td>
        <button type="button" className="btn btn-danger btn-sm" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
    </tr>
  )
}

interface adres {
  id: string
  street: string
  homeNumber: string
  homeLetter: string
  homeAddition: string
  zipcode: string
  city: string
}

function AddEVCEndpointModal({
  show,
  setShowModal,
  setEvcEndpoints,
}: {
  show: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setEvcEndpoints: React.Dispatch<React.SetStateAction<EVCEndpoint[]>>
}) {
  const [endpointType, setEndpointType] = React.useState<EVCEndpointType | null>(null)
  const [portMode, setPortMode] = React.useState<EvcPortMode | null>(null)
  const [innerVlan, setInnerVlan] = React.useState<number>(0)
  const [outerVlan, setOuterVlan] = React.useState<number>(0)

  const [interconnectGroupOptions, setInterconnectGroupOptions] = React.useState<selectType[]>([])
  const [interconnectOptions, setInterconnectOptions] = React.useState<selectType[]>([])
  const [deviceOptions, setDeviceOptions] = React.useState<selectType[]>([])
  const [outerVlanOptions, setOuterVlanOptions] = React.useState<selectType[]>([])

  const [selectedInterconnectGroup, setSelectedInterconnectGroup] = React.useState<any>(null)
  const [selectedInterconnect, setSelectedInterconnect] = React.useState<any>(null)
  const [selectedDevice, setSelectedDevice] = React.useState<any>(null)
  const [selectedPort, setSelectedPort] = React.useState<any>(null)

  const [address, setAddress] = React.useState<adres | null>(null)
  const [postcode, setPostcode] = React.useState('')
  const [huisnummer, setHuisnummer] = React.useState('')
  const [toevoeging, setToevoeging] = React.useState<selectType | null>(null)
  const [toevoegingOptions, setToevoegingOptions] = React.useState<selectType[]>([])

  const darkMode = React.useContext(DarkModeContext).isDarkMode
  const selectStyle = (theme: any) => selectTheme(darkMode, theme)

  const interconnectGroupQuery = useQuery({
    queryKey: ['interconnectGroup'],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/interconnectgroups`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
    enabled: endpointType == EVCEndpointType.INTERCONNECTGROUP,
  })

  React.useEffect(() => {
    if (interconnectGroupQuery.data) {
      setInterconnectGroupOptions(interconnectGroupQuery.data.map((interconnectGroup: any) => {
        return {
          value: interconnectGroup,
          label: interconnectGroup.name,
        }
      }))
    }
  }, [interconnectGroupQuery.data])

  const interconnectQuery = useQuery({
    queryKey: ['interconnect'],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/interconnects`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
    enabled: endpointType == EVCEndpointType.INTERCONNECT,
  })

  React.useEffect(() => {
    if (interconnectQuery.data) {
      setInterconnectOptions(interconnectQuery.data.map((interconnect: any) => {
        return {
          value: interconnect,
          label: `${interconnect.group.name} | ${interconnect.name}`,
        }
      }))
    }
  }, [interconnectQuery.data])

  const deviceQuery = useQuery({
    queryKey: ['device'],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/evcs/devices`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
    enabled: endpointType == EVCEndpointType.DEVICE,
  })

  React.useEffect(() => {
    if (deviceQuery.data) {
      setDeviceOptions(deviceQuery.data.map((device: any) => {
        return {
          value: device,
          label: device.name,
        }
      }))
    }
  }, [deviceQuery.data])

  const adresQuery = useQuery({
    queryKey: ['adres', {
      postcode,
      huisnummer,
    }],

    queryFn: async () => {
      let queryString = `/api/bag/addresses?limit=100`

      if (postcode) {
        queryString += `&zipcode=${encodeURIComponent(postcode)}`
      }

      if (huisnummer) {
        queryString += `&homeNumber=${encodeURIComponent(huisnummer)}`
      }

      const response = await fetch(queryString, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
  })

  React.useEffect(() => {
    if (adresQuery.data) {
      const toevoegingen: selectType[] = []

      adresQuery.data.result.forEach((adres: adres) => {
        // If number is not an exact match
        if (adres.homeNumber == huisnummer) {
          if (!adres.homeAddition && !adres.homeLetter) {
            toevoegingen.push({
              value: adres.id,
              label: 'Geen toevoeging',
            } as selectType)
          }
          if (adres.homeAddition && !adres.homeLetter) {
            toevoegingen.push({
              value: adres.id,
              label: adres.homeAddition,
            } as selectType)
          }
          if (adres.homeLetter && !adres.homeAddition) {
            toevoegingen.push({
              value: adres.id,
              label: adres.homeLetter,
            } as selectType)
          }
          if (adres.homeLetter && adres.homeAddition) {
            toevoegingen.push({
              value: adres.id,
              label: `${adres.homeLetter}-${adres.homeAddition}`,
            } as selectType)
          }
        }
      })

      setToevoegingOptions(toevoegingen)
    }

    // If length is 1, set the address
    if (adresQuery.data && adresQuery.data.result.length === 1) {
      setAddress(adresQuery.data.result[0])
    }
    // If length is > 1, and no additions are available and postcode is fully filled in, Find the exact match and set the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length === 0 && postcode.length === 6) {
      const exactMatch = adresQuery.data.result.find((adres: adres) => {
        return (adres.homeNumber == huisnummer && !adres.homeAddition && !adres.homeLetter)
      })
      if (exactMatch) {
        setAddress(exactMatch)
      }
    }

    // If length is > 1, additions are available, but not set and postcode is fully filled in, Find the exact match and set the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length > 0 && !toevoeging && postcode.length === 6) {
      const exactMatch = adresQuery.data.result.find((adres: adres) => {
        return (adres.homeNumber == huisnummer && !adres.homeAddition && !adres.homeLetter)
      })
      if (exactMatch) {
        setAddress(exactMatch)
      }
    }

    // If toevoeging is selected, set the address
    if (toevoeging) {
      const selectedAddress = adresQuery.data?.result.find((adres: adres) => adres.id == toevoeging.value)
      setAddress(selectedAddress)
    }

    // If length is 0, clear the address
    if (adresQuery.data && adresQuery.data.result.length == 0) {
      setAddress(null)
    }

    // If homenumber doesn't match the selected address, clear the address
    if (adresQuery.data && adresQuery.data.result.length > 0 && address && address.homeNumber != huisnummer) {
      setAddress(null)
    }

    // If postcode is empty or partial, clear the address
    if (postcode == '' || postcode.length < 6) {
      setAddress(null)
    }

    // If length is > 1 and additions are available, and the current selected has no exact match, clear the address
    if (adresQuery.data && adresQuery.data.result.length > 1 && toevoegingOptions.length > 0 && toevoeging && toevoegingOptions.find((option: selectType) => option.value == toevoeging.value)) {
      const selectedAddress = adresQuery.data?.result.find((adres: adres) => adres.id == toevoeging.value)
      if (!selectedAddress || selectedAddress.homeNumber != huisnummer) {
        setAddress(null)
      }
    }

    // If toevoeging is selected but no longer available, clear the selection
    if (toevoeging && toevoegingOptions.length > 0 && !toevoegingOptions.find((option: selectType) => option.value == toevoeging.value)) {
      setToevoeging(null)
    }

    // If toevoeging is selected, but doesn't match the home number, clear the selection
    if (toevoeging && toevoegingOptions.length > 0 && toevoegingOptions.find((option: selectType) => option.value == toevoeging.value)) {
      const selectedAddress = adresQuery.data?.result.find((adres: adres) => adres.id == toevoeging.value)
      if (!selectedAddress || selectedAddress.homeNumber != huisnummer) {
        setToevoeging(null)
        setAddress(null)
      }
    }
  }, [adresQuery.data, huisnummer, postcode, toevoeging, address, toevoegingOptions.length])

  const outerVlanQuery = useQuery({
    queryKey: ['outerVlan'],
    queryFn: async () => {
      const response = await fetch(`/api/netwerk/outerVlan`, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${getAccessToken()}`,
        }),
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return await response.json()
    },
    enabled: portMode == EvcPortMode.QINQ,
  })

  React.useEffect(() => {
    if (outerVlanQuery.data) {
      setOuterVlanOptions(outerVlanQuery.data.map((outerVlan: any) => {
        return {
          value: outerVlan.id,
          label: `VLAN ${outerVlan.id} - ${outerVlan.name}`,
        }
      }))
    }

    console.log(outerVlanQuery.data)
  }, [outerVlanQuery.data])

  const toggleShow = () => setShowModal(!show)

  const handleEndpointTypeSelection = (type: EVCEndpointType) => {
    setEndpointType(type)
  }

  const handleSubmit = () => {
    if (endpointType == null) {
      return
    }

    if (portMode == null) {
      return
    }

    if (portMode == EvcPortMode.QINQ && (innerVlan == 0 || outerVlan == 0)) {
      return
    }

    if (portMode == EvcPortMode.TAGGED && outerVlan == 0) {
      return
    }

    if (portMode == EvcPortMode.UNTAGGED && outerVlan != 0) {
      return
    }

    if (endpointType == EVCEndpointType.INTERCONNECTGROUP && !selectedInterconnectGroup) {
      return
    }

    if (endpointType == EVCEndpointType.INTERCONNECT && !selectedInterconnect) {
      return
    }

    if (endpointType == EVCEndpointType.DEVICE && !selectedDevice && !selectedPort) {
      return
    }

    const evcEndpoint: EVCEndpoint = {
      type: endpointType,
      portMode,
      vlan: {
        inner: innerVlan,
        outer: outerVlan,
      },
    }

    if (endpointType == EVCEndpointType.INTERCONNECTGROUP && selectedInterconnectGroup) {
      evcEndpoint.interconnectGroup = {
        id: selectedInterconnectGroup.id,
        name: selectedInterconnectGroup.name,
      }
    }

    if (endpointType == EVCEndpointType.INTERCONNECT && selectedInterconnect) {
      evcEndpoint.interconnectID = selectedInterconnect.id
    }

    if (endpointType == EVCEndpointType.DEVICE && selectedDevice) {
      evcEndpoint.deviceID = selectedDevice.id
      evcEndpoint.devicePortID = selectedPort.id
    }

    if (endpointType == EVCEndpointType.ADDRESS && address) {
      evcEndpoint.address = address
    }

    setEvcEndpoints(prev => [...prev, evcEndpoint])

    // Close the modal
    toggleShow()
  }

  const submitDisabled = (): boolean => {
    if (endpointType == null) {
      return true
    }

    if (endpointType == EVCEndpointType.INTERCONNECTGROUP && !selectedInterconnectGroup) {
      return true
    }

    if (endpointType == EVCEndpointType.INTERCONNECT && !selectedInterconnect) {
      return true
    }

    if (endpointType == EVCEndpointType.DEVICE && (!selectedDevice || !selectedPort)) {
      return true
    }

    if (portMode == null) {
      return true
    }

    if (portMode == EvcPortMode.QINQ && (innerVlan == 0 || outerVlan == 0)) {
      return true
    }

    if (portMode == EvcPortMode.TAGGED && outerVlan == 0) {
      return true
    }

    if (portMode == EvcPortMode.UNTAGGED && outerVlan != 0) {
      return true
    }

    return false
  }

  React.useEffect(() => {
    setInnerVlan(0)
    setOuterVlan(0)
  }, [portMode])

  React.useEffect(() => {
    setSelectedPort(null)
  }, [selectedDevice])

  return (
    <Modal show={show} centered>
      <Modal.Header>
        <Modal.Title>Endpoint toevoegen</Modal.Title>
        <button className="btn btn-close" color="none" onClick={toggleShow}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {/* Endpoint Type Selection */}
          <div className="row mb-3">
            <div className="col">
              <button
                type="button"
                className={`btn ${endpointType === EVCEndpointType.INTERCONNECTGROUP ? 'btn-primary btn-block' : 'btn-outline-primary btn-block'}`}
                onClick={() => handleEndpointTypeSelection(EVCEndpointType.INTERCONNECTGROUP)}
              >
                Groep
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className={`btn ${endpointType === EVCEndpointType.INTERCONNECT ? 'btn-primary btn-block' : 'btn-outline-primary btn-block'}`}
                onClick={() => handleEndpointTypeSelection(EVCEndpointType.INTERCONNECT)}
              >
                Interconnect
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className={`btn ${endpointType === EVCEndpointType.DEVICE ? 'btn-primary btn-block' : 'btn-outline-primary btn-block'}`}
                onClick={() => handleEndpointTypeSelection(EVCEndpointType.DEVICE)}
              >
                Device
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className={`btn ${endpointType === EVCEndpointType.ADDRESS ? 'btn-primary btn-block' : 'btn-outline-primary btn-block'}`}
                onClick={() => handleEndpointTypeSelection(EVCEndpointType.ADDRESS)}
              >
                Adres
              </button>
            </div>
            {/* <div className="col">
                            <button
                                type='button'
                                className={`btn ${endpointType === EVCEndpointType.CONNECTION ? 'btn-primary btn-block' : 'btn-outline-primary btn-block'}`}
                                onClick={() => handleEndpointTypeSelection(EVCEndpointType.CONNECTION)}
                            >
                                Aansluiting
                            </button>
                        </div> */}
          </div>

          {/* Interconnect Group ID */}
          {endpointType === EVCEndpointType.INTERCONNECTGROUP && (
            <div className="mb-3">
              <label htmlFor="interconnectGroup" className="form-label">Interconnect Group ID:</label>
              <Select
                options={interconnectGroupOptions}
                onChange={(event: any) => setSelectedInterconnectGroup(event.value)}
                isLoading={interconnectGroupQuery.isLoading}
                placeholder="Selecteer een interconnect group"
                theme={selectStyle}
                noOptionsMessage={() => 'Geen interconnect groups gevonden'}
              />
            </div>
          )}

          {/* Interconnect ID */}
          {endpointType === EVCEndpointType.INTERCONNECT && (
            <div className="mb-3">
              <label htmlFor="interconnectId" className="form-label">Interconnect ID:</label>
              <Select
                options={interconnectOptions}
                onChange={(event: any) => setSelectedInterconnect(event.value)}
                isLoading={interconnectQuery.isLoading}
                placeholder="Selecteer een interconnect"
                theme={selectStyle}
                noOptionsMessage={() => 'Geen interconnects gevonden'}
                // value={interconnectOptions.find((option: any) => option?.value?.id === selectedInterconnect?.id)}
              />
            </div>
          )}

          {/* Device ID */}
          {endpointType === EVCEndpointType.DEVICE && (
            <div className="mb-3">
              <label htmlFor="deviceID" className="form-label">Device ID:</label>
              <Select
                options={deviceOptions}
                value={selectedDevice ? selectedDevice.label : null}
                onChange={(event: any) => setSelectedDevice(event.value)}
                isLoading={deviceQuery.isLoading}
                placeholder="Selecteer een device"
                theme={selectStyle}
                noOptionsMessage={() => 'Geen devices gevonden'}
              />
            </div>
          )}

          {/* Device Port */}
          {endpointType === EVCEndpointType.DEVICE && (
            <div className="mb-3">
              <label htmlFor="devicePort" className="form-label">Device Port:</label>
              <Select
                options={selectedDevice?.ports.map((port: any) => {
                  return {
                    value: port,
                    label: port.name,
                  }
                })}
                value={selectedPort ? selectedPort.label : null}
                onChange={(event: any) => setSelectedPort(event.value)}
                placeholder="Selecteer een port"
                theme={selectStyle}
                noOptionsMessage={() => 'Geen ports gevonden'}
              />
            </div>
          )}

          {/* Address */}
          {endpointType === EVCEndpointType.ADDRESS && (
          // 3 columns
            (
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="postcode" className="form-label">Postcode:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={postcode}
                    onChange={(event: any) => setPostcode(event.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <label htmlFor="huisnummer" className="form-label">Huisnummer:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={huisnummer}
                    onChange={(event: any) => setHuisnummer(event.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <label htmlFor="toevoeging" className="form-label">Toevoeging:</label>
                  <Select
                    options={toevoegingOptions}
                    onChange={(event: any) => setToevoeging({ value: event.value, label: event.label })}
                    placeholder="Selecteer een toevoeging"
                    theme={selectStyle}
                    noOptionsMessage={() => 'Geen toevoegingen gevonden'}
                  />
                </div>
              </div>
            )
          )}

          {/* Address */}
          {endpointType === EVCEndpointType.ADDRESS && (
            <div className="mb-3">
              <label htmlFor="address" className="form-label">Geselecteerd adres:</label>
              {!address && (
                <input
                  type="text"
                  className="form-control"
                  value="Geen adres geselecteerd"
                  disabled
                />
              )}
              {address && (
                <input
                  type="text"
                  className="form-control"
                  value={`${address.street} ${address.homeNumber}${address.homeLetter ? address.homeLetter : ''}${address.homeAddition ? address.homeAddition : ''}`}
                  disabled
                />
              )}
            </div>
          )}

          {/* Port Mode Selection */}
          {endpointType && (
            <div className="mb-3">
              <label htmlFor="portMode" className="form-label">Port Mode:</label>
              <Select
                options={[
                  { value: EvcPortMode.UNTAGGED, label: 'Untagged' },
                  { value: EvcPortMode.TAGGED, label: 'Tagged' },
                  { value: EvcPortMode.QINQ, label: 'QinQ' },
                ]}
                onChange={(event: any) => setPortMode(event.value)}
                placeholder="Selecteer een port mode"
                theme={selectStyle}
                noOptionsMessage={() => 'Geen port modes gevonden'}
              />
            </div>
          )}

          {/* VLAN Input */}
          {portMode === EvcPortMode.TAGGED && (
            <div className="mb-3">
              <label htmlFor="vlanOuter" className="form-label">VLAN:</label>
              <input
                type="number"
                className="form-control"
                value={outerVlan}
                onChange={(event: any) => setOuterVlan(Number.parseInt(event.target.value))}
                required
              />
            </div>
          )}

          {portMode === EvcPortMode.QINQ && (
            <div>
              <div className="mb-3">
                <label htmlFor="vlanInner" className="form-label">Inner VLAN:</label>
                <input
                  type="number"
                  className="form-control"
                  value={innerVlan}
                  onChange={(event: any) => setInnerVlan(Number.parseInt(event.target.value))}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="vlanOuter" className="form-label">Outer VLAN:</label>
                <Creatable
                  options={outerVlanOptions}
                  onChange={(event: any) => setOuterVlan(event.value)}
                  isLoading={outerVlanQuery.isLoading}
                  placeholder="Selecteer een outer VLAN"
                  theme={selectStyle}
                  noOptionsMessage={() => 'Geen outer VLANs gevonden'}
                />
              </div>
            </div>
          )}

          {/* Submit Button */}
          <button
            className="btn btn-primary"
            disabled={submitDisabled()}
            onClick={handleSubmit}
            type="button"
          >
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  )
}
