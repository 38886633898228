import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getAccessToken } from '../../services/auth.service'
import { NewestConnectionsCard } from '../../components/NewestConnectionsCard'
import { ChangedConnectionsCard } from '../../components/ChangedConnectionsCard'

export function Dashboard() {
  const statsQuery = useQuery({
    queryKey: ['stats'],

    queryFn: async () => {
      const response = await fetch('/api/netwerk/aansluitingen/stats', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAccessToken()}`,
        },
      })

      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    },
  })

  return (
    <>
      {/* Start with 4 columns info boxes */}
      <div className="row">
        <div className="col-lg-3 col-6">
          {/* small box */}
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{statsQuery.data?.totalConnections}</h3>
              <p>Totaal aantal aansluitingen</p>
            </div>
            <div className="icon">
              <i className="fas fa-network-wired" />
            </div>
          </div>
        </div>
        {/* ./col */}
        <div className="col-lg-3 col-6">
          {/* small box */}
          <div className="small-box bg-danger">
            <div className="inner">
              <h3>{statsQuery.data?.ftuCount}</h3>
              <p>FTU only</p>
            </div>
            <div className="icon">
              <i className="fas fa-network-wired" />
            </div>
          </div>
        </div>
        {/* ./col */}
        <div className="col-lg-3 col-6">
          {/* small box */}
          <div className="small-box bg-warning">
            <div className="inner">
              <h3>{statsQuery.data?.inactiveCount}</h3>
              <p>Inactieve aansluitingen</p>
            </div>
            <div className="icon">
              <i className="fas fa-network-wired" />
            </div>
          </div>
        </div>
        {/* ./col */}
        <div className="col-lg-3 col-6">
          {/* small box */}
          <div className="small-box bg-success">
            <div className="inner">
              <h3>{statsQuery.data?.abboCount}</h3>
              <p>Actieve aansluitingen</p>
            </div>
            <div className="icon">
              <i className="fas fa-network-wired" />
            </div>
          </div>
        </div>
        {/* ./col */}
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <ChangedConnectionsCard />
        </div>
        <div className="col-lg-6 col-12">
          <NewestConnectionsCard />
        </div>
      </div>
    </>
  )
}
